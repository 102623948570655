import React, { Fragment, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import Grid, { Item } from 'components/Grid';
import Image from 'components/Image';

import { small, medium } from 'config/breakpoints';

const GALLERY_ENDPOINT_DEV = 'http://tesseract.local/gallery.php';
const GALLERY_ENDPOINT_PROD = 'https://hejrobin.se/gallery.php';

const GALLERY_ENDPOINT =
	process?.env.NODE_ENV === 'development'
		? GALLERY_ENDPOINT_DEV
		: GALLERY_ENDPOINT_PROD;

const useIntersectionObserver = (options = {}) => {
	const [entries, setEntries] = useState([]);
	const [nodes, setNodes] = useState([]);

	const observer = useRef(null);

	const { root, rootMargin = '0px', threshold = 0.25 } = options;

	useEffect(() => {
		if (nodes.length > 0) {
			observer.current = new IntersectionObserver(
				(observedEntries) => setEntries(observedEntries),
				{ threshold, root, rootMargin }
			);

			nodes.forEach((node) => {
				observer.current.observe(node);
			});
		}

		return () => {
			if (observer.current) {
				observer.current.disconnect();
			}
		};
	}, [nodes, root, rootMargin, threshold]);

	return [observer.current, setNodes, entries];
};

const Overlay = styled.div`
	padding-top: calc(0px + env(safe-inset-area-top));
	padding-bottom: calc(0px + env(safe-inset-area-bottom));
	background: hsl(0, 0%, 0%);
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 300ms ease-in-out;
	will-change: opacity, visibility;
	opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
	visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const CurrentItem = styled.div`
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	width: calc(100% - 8rem);
	height: calc(100% - 8rem);
	position: relative;

	@media ${small}, ${medium} {
		width: 100%;
	}
`;

const Caption = styled.div`
	position: absolute;
	width: 100%;
	height: 4rem;
	line-height: 4rem;
	text-align: center;
	top: -4rem;
	font-weight: 400;
	font-size: 1.6rem;
	letter-spacing: 0.2rem;
	text-transform: uppercase;
	font-family: 'Roboto Mono', monospace;
`;

const Data = styled(Caption)`
	position: absolute;
	display: grid;
	grid-auto-flow: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	top: auto;
	bottom: -4rem;
	z-index: 1010;
`;

const DataItem = styled.span`
	font-size: 1.1rem;
	text-transform: none;

	&::after {
		margin-left: 1rem;
		content: '•';
		opacity: 0.5;
	}

	&:last-child::after {
		content: '';
	}
`;

export default () => {
	const [items, setItems] = useState([]);
	const [currentItem, setCurrent] = useState(null);

	const [observer, setNodes, entries] = useIntersectionObserver();

	const fetchGallery = async () => {
		const request = await fetch(GALLERY_ENDPOINT, {
			credentials: 'omit',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (request.ok) {
			const response = await request.json();
			const { items } = response;

			setItems(items);
		}
	};

	useEffect(() => {
		if (items.length === 0) {
			fetchGallery();
		}
	}, [items]);

	useEffect(() => {
		if (items.length) {
			setNodes(Array.from(document.querySelectorAll('figure')));
		}
	}, [items, setNodes]);

	useEffect(() => {
		entries.forEach((entry) => {
			const { isIntersecting, target } = entry;

			if (isIntersecting) {
				const img = target.querySelector('img');

				img.src = img.dataset.src;

				observer.unobserve(target);
			}
		});
	}, [entries, observer]);

	if (!items.length) return null;

	return (
		<Fragment>
			<Grid>
				{items
					.sort((a, b) => b.capturedAt - a.capturedAt)
					.map((item) => (
						<Item ratio={item.size.ratio} onClick={() => setCurrent(item)}>
							<Image size={item.size} src={`/uploads/${item.fileName}`} />
						</Item>
					))}
			</Grid>
			<Overlay isVisible={!!currentItem} onClick={() => setCurrent(null)}>
				{currentItem && (
					<CurrentItem
						style={{
							backgroundImage: `url(/uploads/${currentItem.fileName})`,
						}}>
						<Caption>{currentItem.caption}</Caption>
						<Data>
							{currentItem.focalLength && (
								<DataItem>{currentItem.focalLength}mm</DataItem>
							)}
							{currentItem.focalNumber && (
								<DataItem>f{currentItem.focalNumber}</DataItem>
							)}
							{currentItem.exposureTime && (
								<DataItem>{currentItem.exposureTime}s</DataItem>
							)}
							{currentItem.isoValue && (
								<DataItem>ISO {currentItem.isoValue}</DataItem>
							)}
						</Data>
					</CurrentItem>
				)}
			</Overlay>
		</Fragment>
	);
};
