import React from 'react';
import styled from 'styled-components';

import Biography from 'views/Biography';
import Gallery from 'views/Gallery';

const Wrapper = styled.div`
	font-family: 'Inter var', Helvetica Neue, Helvetica, sans-serif;
	user-select: none;
`;

export default () => (
	<Wrapper>
		<Biography />
		<Gallery />
	</Wrapper>
);
