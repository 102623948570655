import React from 'react';
import styled from 'styled-components';

import { medium, small } from 'config/breakpoints';

import Image from 'components/Image';

const Wrapper = styled.div`
	position: relative;
	height: 100vh;
	min-height: 32rem;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const Container = styled.div`
	background: linear-gradient(
		to bottom,
		hsla(0, 0%, 0%, 0.5) 90%,
		hsl(0, 0%, 0%)
	);
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: grid;
	grid-auto-flow: row;
	align-items: end;
	justify-content: center;
	align-content: end;
`;

const Prefix = styled.span`
	padding: 0 4rem;
	margin-bottom: 1rem;
	max-width: 120rem;
	font-size: 1rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: 0.2rem;
	word-spacing: 0.5rem;
	text-transform: uppercase;
	color: hsl(0, 0%, 100%);
`;

const Heading = styled.h1`
	padding: 0 4rem;
	margin-bottom: 4rem;
	max-width: 120rem;
	font-size: 4rem;
	font-weight: 600;
	line-height: 1.5;
	text-align: left;
	color: hsl(0, 0%, 100%);

	@media ${medium} {
		font-size: 3rem;
	}

	@media ${small} {
		font-size: 2rem;
	}
`;

const Link = styled.a`
	color: hsl(336, 56%, 84%);
	text-decoration: none;
	border-bottom: 0.2rem solid currentColor;

	@media (hover: hover) {
		&:hover {
			color: hsl(335, 66%, 59%);
			border-bottom-color: currentColor;
		}
	}
`;

Link.defaultProps = {
	rel: 'external',
	target: '_blank',
};

export default () => (
	<Wrapper>
		<Image preloaded src="/portrait.jpg" />
		<Container>
			<Prefix>Robin Grass</Prefix>
			<Heading>
				Creative{' '}
				<Link href="https://github.com/hejrobin">JavaScript developer</Link>,
				portrait{' '}
				<Link href="https://instagram.com/robin.stills">photographer</Link>,
				hobbyist{' '}
				<Link href="https://dribbble.com/hejrobin">graphic designer</Link>, shoe
				hoarder,{' '}
				<Link href="https://untappd.com/user/hemligt">beer enthusiast</Link>,
				whisky connisseur and foodie.
			</Heading>
		</Container>
	</Wrapper>
);
