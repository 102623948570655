import styled from 'styled-components';

import { large, medium, small } from 'config/breakpoints';

const Grid = styled.div`
	margin: 0 auto;
	margin-top: 10rem;
	padding: 4rem;
	column-count: 4;
	column-gap: 4rem;
	height: 100%;
	max-width: 120rem;

	@media ${large} {
		column-count: 3;
	}

	@media ${medium} {
		column-count: 2;
	}

	@media ${small} {
		column-count: 1;
	}
`;

export default Grid;

export const Item = styled.figure`
	background: hsla(0, 0%, 100%, 0.05);
	margin-bottom: 5rem;
	padding-top: ${(props) => props.ratio || 0}%;
	overflow: hidden;
	position: relative;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;

	@media ${medium}, ${small} {
		margin-bottom: 4rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;
