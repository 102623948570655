import React, { useState } from 'react';
import styled from 'styled-components';

const Source = styled.img`
	display: block;
	width: 100%;
	height: auto;
	user-select: none;
	transition: all 500ms ease-in-out;
	opacity: ${(props) => (props.loaded ? 1 : 0)};
	transition-delay: 1000ms;
	object-fit: contain;
	position: absolute;
	top: 0;
`;

const NOOP = () => {};

export default (props) => {
	const [isLoaded, setIsLoaded] = useState(false);

	const handleDidLoad = () =>
		setTimeout(() => setIsLoaded(true), props.delay > 0 ? props.delay : 0);

	const handleDidClick = props.onClick || NOOP;

	return (
		<Source
			src={props.preloaded ? props.src : null}
			data-src={props.src}
			data-pin-nopin="true"
			loaded={isLoaded}
			onLoad={handleDidLoad}
			onClick={handleDidClick}
		/>
	);
};
