/* @dependencies */
import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { createGlobalStyle } from 'styled-components';

/* @components */
import Bootstrap from './Bootstrap';

const GlobalStyles = createGlobalStyle`
	body {
		background: hsl(0, 0%, 0%);
		color: hsl(0, 0%, 100%);
	}
`;

window.addEventListener('DOMContentLoaded', async () => {
	const mountNode = document.getElementById('root');

	render(
		<Fragment>
			<GlobalStyles />
			<Bootstrap />
		</Fragment>,
		mountNode
	);
});
